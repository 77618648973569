
import { defineComponent, onBeforeMount, ref } from 'vue';
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonBadge,
    IonRouterOutlet,
    IonSkeletonText,
} from '@ionic/vue';

import firebase from 'firebase';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  name: 'Packages',
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonBadge,
    IonRouterOutlet,
    IonSkeletonText,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const firestore = firebase.firestore();
    const packageTypes: Array<any> = [];

    const selectedTab = ref();
    const loading = ref(true);


    const segmentChanged = () => {
      router.replace(`/home/packages/${selectedTab.value}`);
    }

    onBeforeMount(async () => {
      const packageTypeSnapshot = await firestore
        .collection('registry')
        .orderBy('label', 'asc')
        .get();

      packageTypeSnapshot.forEach(packageType => packageTypes.push({uid: packageType.id, ...packageType.data()}));

      if (route.params?.registry) {
        // TODO: Check if package type exists
        selectedTab.value = route.params.registry;
      } else {
        selectedTab.value = packageTypes[0]?.uid;
        segmentChanged();
      }

      loading.value = false;
    });

    return {
      loading,
      packageTypes,
      selectedTab,
      segmentChanged,
    };
  }
});
