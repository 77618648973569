<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title> Registries </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div class="ion-padding wrapper">
        <ion-segment v-if="!loading" v-model="selectedTab" @ionChange="segmentChanged()">
          <ion-segment-button v-for="item in packageTypes" :value="item.uid" :key="item.uid">
            <ion-label>
              {{item.label}}
            </ion-label>
          </ion-segment-button>
        </ion-segment>

        <div v-else class="segment-skeleton segment-wrapper">
          <ion-skeleton-text animated />
          <div class="segment-placeholder" />
          <div class="segment-placeholder" />
          <div class="segment-placeholder" />
        </div>

        <div class="router-wrapper">
          <ion-router-outlet id="selected-package-type-content" />
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts" src="./packages.ts" />
<style scoped src="./packages.css" />
